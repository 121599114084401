import React from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Buy from "./components/buy/Buy";
import About from "./components/About";
import Tokenomic from "./components/Tokenomics";
import Footer from "./components/Footer";
// eslint-disable-next-line
import CustomCursor from "./components/CustomCursor";
import styled from "styled-components";
import "./styles/Home.css";
import Dex from "./assets/dex-screener-seeklogo.svg";
import Coingecko from "./assets/coingecko-svgrepo-com.svg";
import Telegram from "./assets/icons8-telegram-logo-48.png";
// eslint-disable-next-line
import Price from "./components/Price";
import XLOGO from "./assets/x.png";

const XLogo = () => <img alt="svgImg" src={XLOGO} />;

const App = () => {
  return (
    <div className="App">
      {/* <Price /> */}

      {/* <CustomCursor /> */}
      <Navbar />
      <MainContainer>
        <HeaderSection id="home">
          <Header />
        </HeaderSection>
        <Buy />
        <About />
        <TokenomicSection id="tokenomic">
          <Tokenomic />
        </TokenomicSection>
        <Footer />
      </MainContainer>
      <IconContainer>
        <IconLink
          href="https://x.com/AELON177"
          target="_blank"
          rel="noopener noreferrer"
        >
          <XLogo />
        </IconLink>
        <IconLink
          href="https://dexscreener.com/solana/7kdeb1jfpim3qaz2iaeyh1s5ynwfydgs78v79j2dftpw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Dex} alt="Dex Logo" />
        </IconLink>
        <IconLink
          href="https://www.geckoterminal.com/solana/pools/7kDEB1jfPim3QAZ2iaeYh1S5yNWFYdgS78V79j2dFTPW"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Coingecko} alt="Coingeko Logo" />
        </IconLink>
        <IconLink
          href="https://t.me/Aelon_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Telegram} alt="Telegram logo" />
        </IconLink>
      </IconContainer>
    </div>
  );
};

const MainContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeaderSection = styled.section`
  padding: 40px 0;
`;

const TokenomicSection = styled.section`
  padding: 40px 0;
`;

const IconContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
  }
  p {
    color: white;
    font-size: 16px;
    margin-left: 10px;
  }
`;

export default App;
