import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Price = () => {
  const [price, setPrice] = useState(null);
  const [showPrice, setShowPrice] = useState(true);
  let lastScrollY = 0;

  useEffect(() => {
    fetch(
      "https://api.geckoterminal.com/api/v2/simple/networks/solana/token_price/E762M7pP5vBTfLBiqr1M7isvJYZ3moGZ7rxhgy93o5dS"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const price =
          data?.data?.attributes?.token_prices
            ?.E762M7pP5vBTfLBiqr1M7isvJYZ3moGZ7rxhgy93o5dS;
        if (price) {
          setPrice(price);
        } else {
          setPrice("fetching the Price");
        }
      })
      .catch((error) => console.error("Error fetching data:", error));

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowPrice(false); // Hide price when scrolling down
      } else {
        setShowPrice(true); // Show price when scrolling up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showPrice && (
        <PriceContainer>
          {price ? `Price: $${price}` : "Loading Price..."}
        </PriceContainer>
      )}
    </>
  );
};

const PriceContainer = styled.div`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #c1ff00;
  z-index: 9999;
  width: auto;
  max-width: 80%;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 8px;
  }
`;

export default Price;
