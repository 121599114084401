// src/About.js
import React from "react";
import alienMusk from "../assets/dd.png";
import "./About.css";

const About = () => {
  return (
    <section id="about" className="about">
      <h2>About AELON</h2>
      <img src={alienMusk} alt="Alien Musk" className="alien-musk-img" />
      <p>
        A<span>ELON </span>is out of the box #GAMEFi project in the crypto
        space. We are aiming to bring fun and value to the community.
      </p>
      <br />
      <p>
        <span>#A</span>ELON is the Alien version.
        <br /> The game narrative that is being developed is <span>#A</span>ELON
        trying reach out to Alien world and putting efforts to reach back where
        he belongs.
      </p>
    </section>
  );
};

export default About;
