import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import CI1 from "../assets/aa.png";
import u from "../assets/dd.png";

// Chatbot Component
const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "Hey there, I'm AELON AI Agent 👋", sender: "bot" },
    { text: "How can I help you today?", sender: "bot" },
  ]);
  const [input, setInput] = useState("");

  // Handle send message
  const handleSend = async () => {
    if (input.trim() === "") return;

    const userMessage = { text: input, sender: "user" };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "You are a helpful assistant." },
            ...messages.map((msg) => ({
              role: msg.sender === "user" ? "user" : "assistant",
              content: msg.text,
            })),
            { role: "user", content: input },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const botReply = {
        text: response.data.choices[0].message.content,
        sender: "bot",
      };

      setMessages((prev) => [...prev, botReply]);
      setInput(""); // Reset input after sending

      // Output the bot's reply via speech
      speakText(response.data.choices[0].message.content);
    } catch (error) {
      console.error("Error sending message:", error);
      const errorReply = {
        text: "Sorry, there was an error. Please try again later.",
        sender: "bot",
      };
      setMessages((prev) => [...prev, errorReply]);
    }
  };

  // Function to convert text to speech
  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US"; // Language can be adjusted
    speechSynthesis.speak(utterance);
  };

  return (
    <Wrapper>
      <ChatContainer>
        <ChatHeader>
          <img
            src={u}
            alt="potus"
            height={90}
            width={90}
            style={{ borderRadius: "50%" }}
          />
          <HeaderText>
            <span style={{ color: "#000" }}>AELON</span>
          </HeaderText>
          <img
            src={u}
            alt="potus"
            height={90}
            width={90}
            style={{ borderRadius: "50%" }}
          />
        </ChatHeader>
        <ChatBody>
          {messages.map((msg, index) => (
            <Message key={index} sender={msg.sender}>
              <MessageContent sender={msg.sender}>
                {msg.sender === "bot" && (
                  <BotImage src={CI1} height={90} width={90} alt="Bot" />
                )}
                <Text>{msg.text}</Text>
              </MessageContent>
            </Message>
          ))}
        </ChatBody>
        <ChatFooter>
          <ChatInput
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSend();
            }}
            placeholder="Ask me..."
          />
          <SendButton onClick={handleSend}>↵</SendButton>
        </ChatFooter>
      </ChatContainer>
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full height of the viewport
  background-color: rgb(0, 0, 0); // Light background for contrast
`;

const ChatContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 650px;
  background-color: #000;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const ChatBody = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: rgb(0, 0, 0);
`;

const ChatFooter = styled.div`
  display: flex;
  padding: 10px;
  background-color: rgb(0, 0, 0);
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
`;

const SendButton = styled.button`
  background-color: #c1ff00;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
`;

const Message = styled.div`
  margin: 5px 0;
  align-self: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  background-color: ${(props) =>
    props.sender === "user" ? "#c1ff00" : "#c1ff00"};
  color: black;
  padding: 8px 12px;
  border-radius: 50px;
  border-bottom-left-radius: 0.25rem;
  max-width: 50%;
  word-wrap: break-word;
  display: flex;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const MessageContent = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.sender === "user"
      ? "flex-direction: row-reverse;"
      : "flex-direction: row;"}
`;

const BotImage = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const Text = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

const ChatHeader = styled.div`
  background-color: #c1ff00;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const HeaderText = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

export default Chatbot;
